/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('jquery-ui/ui/widgets/datepicker');
    require('jquery-ui/ui/widgets/autocomplete');
    require('bootstrap');
    require('bootstrap-table/dist/bootstrap-table.min');
    require('multiselect-two-sides/dist/js/multiselect.min')
    require('jstree/dist/jstree.min')
    require('jquery-treegrid/js/jquery.treegrid.min')
    require('bootstrap-table/dist/extensions/treegrid/bootstrap-table-treegrid.min');
    require('bootstrap-table/dist/extensions/resizable/bootstrap-table-resizable.min');
    require('bootstrap-select/dist/js/bootstrap-select.min')
    require('jquery-validation/dist/jquery.validate.min')
    require('jquery-validation/dist/additional-methods.min')
    require('bootstrap4-toggle/js/bootstrap4-toggle.min')
    require('jquery-contextmenu/dist/jquery.contextMenu.min')
    window.hljs = require('highlight.js/lib/common')
    window.Web3 = require('web3/dist/web3.min')


    jQuery.validator.setDefaults({


        highlight: function (element) {
            jQuery(element).closest('.form-control').addClass('is-invalid');
        },
        unhighlight: function (element) {
            jQuery(element).closest('.form-control').removeClass('is-invalid').addClass('is-valid')
        },

        errorElement: 'div',
        errorClass: 'invalid-feedback',
        errorPlacement: function (error, element) {
            if (element.parent('.input-group-prepend').length) {
                $(element).siblings(".invalid-feedback").append(error);
                //error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
        },
    });


} catch (e) {
}



